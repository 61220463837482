<template>
  <div class="auth-wrapper auth-v1">

    <!-- Not valid token -->
    <div v-if="!isValidToken">
      <v-card class="auth-card">
        <v-card-title> No se pudo validar la solicitud de cambio de contraseña 
          <v-icon class="ms-3">{{ icons.mdiAlert }}</v-icon>
        </v-card-title>
        <v-card-text> vuelva al inicio e ingrese nuevamente un email valido para su recuperación, <br> a continuación siga las instrucciones enviadas a su correo electrónico</v-card-text>
        <v-card-actions class="d-flex align-center">
          <router-link
            :to="{ name: 'auth-login' }"
            class="d-flex align-center text-sm"
          >
            <v-icon size="24" color="primary">
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Volver al inicio</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    <!-- Valid token view -->
    <div v-if="isValidToken && !success" class="auth-inner">
      <v-card class="auth-card">
        <!-- logo and title -->
        <v-card-title class="d-flex align-center justify-center py-3">
            <v-card-text>
              <v-img
                width="60%"
                class="mx-auto my-4"
                :src="require(`@/assets/logos/PNG/Recurso 2.png`)"
              ></v-img>
            </v-card-text>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Cambiar Contraseña 🔒
          </p>
          <ul class="text-caption font-italic">
            <li>Tu nueva contraseña debe ser diferente a cualquier otra utilizada anteriormente</li>
            <li>Debe contener caracteres y números</li>
            <li>No puede ser muy similar al nombre de usuario</li>
            <li>Mínimo de 8 caracteres</li>
          </ul>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="change_pass_form">
            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Nueva Contraseña"
              placeholder="············"
              :append-icon="
                isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
              "
              class="mb-3"
              :rules="[rules.required,rules.minLength]"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-text-field
              v-model="confirmPassword"
              outlined
              :type="isConfirmPasswordVisible ? 'text' : 'password'"
              label="Repite Contraseña"
              placeholder="············"
              :append-icon="
                isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
              "
              :rules="[rules.required, rules.minLength]"
              @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
            ></v-text-field>

            <v-col align="right" v-if="errorMessage">
              <span class="caption" style="color:red;">
                {{ errorMessage }}
              </span>
            </v-col>

            <v-btn block color="primary" 
            class="mt-4" 
            @click="changePassword"
            >
              Confirmar nueva contraseña
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- back to login -->
        <v-card-actions class="d-flex justify-center align-center">
          <router-link
            :to="{ name: 'auth-login' }"
            class="d-flex align-center text-sm"
          >
            <v-icon size="24" color="primary">
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Volver al inicio</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    <!-- change password success -->
    <div v-if="success">
      <v-card class="auth-card">
        <v-card-title> ¡Solicitud exitosa! 
          <v-icon class="ms-3">{{ icons.mdiCheckDecagram }}</v-icon>
        </v-card-title>
        <v-card-text> vuelva al inicio e ingrese con su nueva contraseña</v-card-text>
        <v-card-actions class="d-flex align-center">
          <router-link
            :to="{ name: 'auth-login' }"
            class="d-flex align-center text-sm"
          >
            <v-icon size="24" color="primary">
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Volver al inicio</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="
        require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)
      "
    />

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiEyeOutline, mdiEyeOffOutline, mdiAlert, mdiCheckDecagram } from "@mdi/js";
import { ref } from "@vue/composition-api";
import themeConfig from "@themeConfig";

export default {
  data: () => {
    return {
      password: "",
      confirmPassword: "",
      token: '',
      isValidToken: false,
      rules: {
        required: value => !!value || 'Este campo es obligatorio',
        minLength: value => value && value.length  >= 8 || 'Ingrese un mínimo de 8 carácteres',
      },
      success: false,
      errorMessage: ''
    };
  },
  async beforeMount(){
    const urlSearchParams = new URLSearchParams(window.location.search);
    this.token = urlSearchParams.get('t')
    const response = await this.$api.validateResetPassToken({ token: this.token})
    this.isValidToken = (response.status === 200) ? true : false
  },
  methods: {
    async changePassword() {
      
      if(this.isFormValid){
        
        if(this.password !== this.confirmPassword) {
          this.errorMessage = 'Las contraseñas deben coincidir'
        } else {

          try {
            this.errorMessage = ''
            const form = {
              token: this.token,
              password: this.password,
            }
            const response = await this.$api.changeUserPassword(form)
            this.success = true
            
          } catch (error) {
            if(error.response.status === 400){
              this.errorMessage = 'La contraseña no cumple con los requisitos'
            }
          }
        }
      }
    },
  },
  computed: {
    isFormValid() {
      return this.$refs.change_pass_form && this.$refs.change_pass_form.validate();
    }
  },
  setup() {
    const isPasswordVisible = ref(false);
    const isConfirmPasswordVisible = ref(false);
    const password = ref("");
    const confirmPassword = ref("");

    return {
      isPasswordVisible,
      isConfirmPasswordVisible,
      password,
      confirmPassword,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiAlert,
        mdiCheckDecagram
      },
    };
  },
};
</script>

<style lang="scss">
@import "@core/preset/preset/pages/auth.scss";
</style>
